
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Icon from 'UI/Icon.vue';
import CustomTooltip from 'UI/CustomTooltip.vue';

interface Data {
    inputValue: number;
    showErrorText: boolean;
}

interface Methods {
    increase: () => void;
    decrease: () => void;
    setMaxValue: () => void;
    closeTooltip: () => void;
}

interface Computed {
    isThemeDark: boolean;
    hasError: boolean;
}

export default Vue.extend<Data, Methods, Computed, any>({
    components: {
        CustomTooltip,
        Icon,
    },
    props: {
        availableAmount: {
            type: Number,
            required: true,
        },
        assetSymbol: {
            type: String,
            required: true,
        },
        validations: {
            type: undefined,
            default: undefined,
        },
        errorText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            inputValue: 0,
            showErrorText: false,
        };
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
        hasError() {
            return this.validations?.$error;
        },
    },
    methods: {
        increase() {
            if (this.availableAmount === 0) {
                this.inputValue += 1;
                return;
            }
            this.inputValue += Number(this.availableAmount) * 0.05;
        },
        decrease() {
            if (this.availableAmount === 0) {
                this.inputValue -= 1;
                return;
            }
            this.inputValue -= Number(this.availableAmount) * 0.05;
        },
        setMaxValue() {
            this.inputValue = this.availableAmount;
        },
        closeTooltip() {
            this.showErrorText = false;
        },
    },
    watch: {
        inputValue(value) {
            const stringValue = String(value).replace(',', '.');
            const floatPart = stringValue.split('.')[1];
            const intPart = stringValue.split('.')[0];
            if (floatPart && floatPart.length > 8) {
                this.inputValue = Number(`${intPart}.${floatPart.slice(0, 8)}`);
            }
            if (value < 0) {
                this.inputValue = 0;
            }
            this.$emit('input', value);
        },
        hasError(val) {
            this.showErrorText = val;
        },
    },
});
