// This file was autogenerated. Please do not change.
export interface IGetQuotaRequest {
    accountId: string;
    asset: string;
    duration: number;
    placementName: string;
}

export default class GetQuotaRequest {
    readonly _accountId: string;

    /**
     * Example: AKP2W4P2VHE
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _asset: string;

    /**
     * Example: ETH
     */
    get asset(): string {
        return this._asset;
    }

    readonly _duration: number;

    /**
     * Example: 30
     */
    get duration(): number {
        return this._duration;
    }

    readonly _placementName: string;

    /**
     * Example: Binance
     */
    get placementName(): string {
        return this._placementName;
    }

    constructor(props: IGetQuotaRequest) {
        this._accountId = props.accountId.trim();
        this._asset = props.asset.trim();
        this._duration = props.duration;
        this._placementName = props.placementName.trim();
    }

    serialize(): IGetQuotaRequest {
        const data: IGetQuotaRequest = {
            accountId: this._accountId,
            asset: this._asset,
            duration: this._duration,
            placementName: this._placementName,
        };
        return data;
    }

    toJSON(): IGetQuotaRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            asset: typeof this._asset === 'string' && !!this._asset.trim(),
            duration: typeof this._duration === 'number',
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
